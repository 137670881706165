import React, { useEffect, useState } from 'react';
import header_img_black from '../../../resources/idca-logo.png';

import { getAuth, signOut } from "firebase/auth";

import { VscMenu } from 'react-icons/vsc'

import testImg from '../../../resources/DCISbg.png'

import { removeCookie } from 'react-use-cookie';


export default function MemberNavbar({ user, page, not_signed_in }) {

    const [userInfo, setUserInfo] = useState(user)
    const [roles, setRoles] = useState([]);
    const [numNotifications, setNumNotifications] = useState(0)

    useEffect(() => {
        console.log(user)
        setUserInfo(user)
        setRoles(user?.idcaRole)
        const numNotif = user?.unresolvedNotification
        if (numNotif)
            setNumNotifications(numNotif)
    }, [user])

    const nav_menu_handler = () => {
        var myTopNav = document.getElementById('myTopnavWhite');
        var logo = document.getElementById('idca_nav_logo_white');


        var y = document.getElementById("nav_body");
        var x = document.getElementById("myTopnav");
        if (myTopNav != null && logo != null) {
            if (myTopNav.className === "nav_menu") {
                myTopNav.className += " responsive_nav_men";
                logo.className = "nav_logo_h"
                var y1 = document.getElementById('nav_body_white');
                if (y1 != null) {
                    y1.style.height = "unset"
                }
            } else {
                myTopNav.className = "nav_menu";
                logo.className += " nav_logo_responsive"

                if (x != null) {
                    x.style.marginTop = "25px"
                }
                if (y != null) {
                    y.style.height = "unset"
                }
                var y1 = document.getElementById('nav_body_white');
                if (y1 != null) {
                    y1.style.height = "100px"
                }

            }
        }
    }
    function logout() {
        if (window.confirm("Are you sure you want to log out?")) {
            const auth = getAuth();
            signOut(auth).then(() => {
                window.location.href = "/login";
                removeCookie("member", { httpOnly: false, domain: '.idc-a.org' })
                // Sign-out successful.
            }).catch((error) => {
                // An error happened.
            });
        }
    }

    return (
        <div id={'nav_body_white'}>
            <header id={'nav_header_nav_white'} className="Saira_Medium_White">
                <nav className="member_nav_nav">
                    <div className="nav_nav_div_top_1">
                        <h1 className="nav_logo_h nav_logo_responsive">
                            <a href="https://idc-a.org/">
                                <div className="nav_img" id='idca_nav_logo_white' >
                                    <img src={header_img_black} width={120} height={60} />
                                </div>
                            </a>
                        </h1>
                        {!not_signed_in ?
                            <ul className="nav_menu" id='myTopnavWhite'>
                                <li className="nav_logo_img_men">
                                    <a href="https://idc-a.org/" className="nav_img_logo_small_link">
                                        <div className="nav_logo_img_men" id='nav_logo_img_men_id_white' >
                                            <img src={header_img_black} width={120} height={60} />
                                        </div>
                                    </a>
                                </li>
                                <div className="nav_nav_div_top_2">
                                    <li>
                                        <a href="/" style={{ color: page == 'CoursesCertification' && '#AD2225', backgroundColor: page == 'CoursesCertification' && 'white', marginRight: '10px' }}>Home</a>
                                    </li>
                                    {roles ?
                                        (roles.includes("member") || roles.includes("admin") || roles.includes("sales") || roles.includes("membership-admin") || roles.includes("corporate-member-admin")) &&
                                        <li>
                                            <a href="/intelligence-resources" style={{ color: page == 'IntelligenceResources' && '#AD2225', backgroundColor: page == 'IntelligenceResources' && 'white' }}>Intelligence Resources</a>
                                        </li>
                                        : ""}

                                    {roles ?
                                        (roles.includes("member") || roles.includes("admin") || roles.includes("sales") || roles.includes("membership-admin") || roles.includes("corporate-member-admin")) &&
                                        <li>
                                            <a href="https://forum.idc-a.org" target="_blank" rel="noreferrer">Discussion</a>
                                        </li>
                                        : ""
                                    }

                                    <li>
                                        <a href="/all-courses" style={{ color: page == 'CoursesCertification' && '#AD2225', backgroundColor: page == 'CoursesCertification' && 'white', marginRight: '30px' }}>Courses</a>
                                    </li>

                                    {roles ?
                                        (roles.includes("member")
                                            || roles.includes("admin")
                                            || roles.includes("corporate-member-admin")
                                            || roles.includes("sales")
                                            || roles.includes("membership-admin")) &&
                                        <li style={{ marginLeft: '50px', marginRight: '50px' }}>
                                            <a href='/mileage' style={{ color: page == 'mileage' && '#AD2225', backgroundColor: page == 'mileage' && 'white' }}>
                                                {userInfo?.mileageScore ? userInfo.mileageScore : '0'} pts
                                            </a>
                                        </li>
                                        : ""}

                                    <li>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {userInfo?.photoURL ?
                                                <img src={userInfo?.photoURL} alt="User Profile Picture" className='Dashboard_Left_Pannel_Profile_Pic'
                                                    style={{ width: '30px', height: '30px', position: 'relative', top: '10px' }} />
                                                :
                                                <div style={{ position: 'relative', top: '10px' }}>
                                                    <img src={testImg} alt="User Profile Picture" className='Dashboard_Left_Pannel_Profile_Pic' width={30} height={30} />
                                                </div>
                                            }
                                            <p>Hi, <span className='Saira_Bold_Black'>{userInfo?.firstName}</span></p>
                                        </div>
                                        <ul className="nav_submenu">
                                            {roles ?
                                                (roles.includes("instructor") || roles.includes("admin") || roles.includes("sales") || roles.includes("membership-admin") || roles.includes("corporate-member-admin")) &&
                                                <li >
                                                    <a href='/admin'>
                                                        Admin Panel
                                                    </a>
                                                </li>
                                                : ""
                                            }
                                            <li>
                                                <a style={{ cursor: 'pointer' }} onClick={() => logout()}>Logout</a>
                                            </li>
                                        </ul>
                                    </li>
                                </div>
                            </ul>
                            : null}
                    </div>

                    {!not_signed_in ?
                        <div id="navBar_image_menu" className={'nav_menu_img_white'} onClick={nav_menu_handler}>
                            <VscMenu className='navBar_image_menu_icon' style={{ left: '-100px' }} />
                        </div>
                        : null}
                </nav >
            </header >
        </div >
    )
}
