import React, { useState, useEffect } from 'react';
import { getFirestore, limit, getDocs, query, collection, where, orderBy, deleteDoc, doc } from "firebase/firestore";
import { BsFillTrashFill } from "react-icons/bs"
import { clientSanity } from '../../../Components/Sanity/client';
import { getAuth } from "firebase/auth";
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TiClipboard } from 'react-icons/ti'


function PendingPot() {
    const [pending, setPending] = useState([])
    const db = getFirestore()
    const options = { day: 'numeric', month: 'long' };


    useEffect(() => {
        function_use_effect()
    }, [])

    async function function_use_effect() {
        const q = query(collection(db, "Pending Pot"));
        var result = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            result.push({ ...doc.data(), id: doc.id })
        })
        setPending(result)
    }

    async function deletePending(id) {
        if (window.confirm('Delete?')) {
            await deleteDoc(doc(db, "Pending Pot", id));
            // Get data again
            function_use_effect()
        }
    }


    return (
        <div className="Dashboard_Right_Pannel_whole">
            <div className='admin_event_list_individual_course_top_div'>
                <h1 className='Saira_Bold_Black'>Pending Pot</h1>
                <div className='admin_event_list_outer_div Saira_Bold_Black'>
                    <div className='admin_event_list_individual_course_div'>
                        <h4>Name</h4>
                        <p>Email</p>
                        <p>Location</p>
                        <p>Event</p>
                        <p>Event Date</p>
                        <p>Type</p>
                    </div>
                    <p>Actions</p>
                </div>

                {pending.map((attendee, i) => (
                    <div key={i} className={'admin_event_list_outer_div Saira_Medium_Gray admin_event_list_individual_course_div_' + i % 2}>
                        <div className='admin_event_list_individual_course_div Saira_Medium_Gray'>
                            <a href={'/users/' + attendee.uid}>
                                <h4>{attendee.user_name}</h4>
                            </a>
                            <p>{attendee.email}</p>
                            <p>{attendee.event_location}</p>
                            <a href={'/admin/events/course?id=' + attendee.event_id}>
                                <p>{attendee.event_title}</p>
                            </a>
                            <p>{(new Date(attendee.event_date)).toLocaleDateString('en-US', options)}</p>
                            <p>{attendee.in_person ? 'in-person' : 'virtual'}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                            <CopyToClipboard
                                text={attendee.email}
                                onCopy={() => alert("Copied email: " + attendee.email)}
                            >
                                <button>
                                    <TiClipboard size={20} />
                                </button>
                            </CopyToClipboard>

                            <button
                                onClick={() => deletePending(attendee.id)}
                                className='Saira_Medium_Red admin_event_list_delete_button'>
                                <BsFillTrashFill />
                            </button>
                        </div>
                    </div>
                ))
                }
            </div >
        </div >
    );
}


export default PendingPot

