import React, { useEffect, useState } from 'react';
import testImg from '../../resources/DCISbg.png'
import { getFirestore, doc, getDocs, getDoc, updateDoc, where, query, collection, orderBy } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { IoMdAdd } from "react-icons/io"
import { MdEdit } from 'react-icons/md'
import { ImCancelCircle } from 'react-icons/im'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BsCheck2Circle } from 'react-icons/bs'
import SuccessfulRegistration from '../../Components/EmailServer/SuccessfulRegistration';
import axios from 'axios';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TiClipboard } from 'react-icons/ti'
import { AiFillFileAdd } from 'react-icons/ai'
import RegisterUserForZoomEvent from '../../Components/RegisterZoomEvent/RegisterZoomEvent';
import Modal from 'react-modal';


async function saveChanges(user, user_id) {
    const db = getFirestore();
    getAuth()
    const userDoc = doc(db, "Users", user_id);
    var phone = document.getElementById(`user_phone_${user?.email}`).value;
    var title = document.getElementById(`user_title_${user?.email}`).value;
    var company = document.getElementById(`user_company_${user?.email}`).value;
    var address = document.getElementById(`user_street_${user?.email}`).value;
    var city = document.getElementById(`user_city_${user?.email}`).value;
    var zipcode = document.getElementById(`user_zipcode_${user?.email}`).value;
    var state = document.getElementById(`user_state_${user?.email}`).value;
    var country = document.getElementById(`user_country_${user?.email}`).value;
    var news = document.getElementById(`user_news_subscribed_${user?.email}`).checked;
    var mileageScore = document.getElementById(`user_mileageScore_${user?.email}`).value;
    var mileageScoreSet = document.getElementById(`user_mileageScore_set_${user?.email}`).value;
    mileageScore = mileageScore ? mileageScore : 0;

    await updateDoc(userDoc, {
        phone: phone,
        title: title,
        company: company,
        address: address,
        city: city,
        zipcode: zipcode,
        state: state,
        country: country,
        newsSubscribed: news,
        mileageScore: mileageScoreSet != user.mileageScore ? mileageScoreSet : user.mileageScore ? parseInt(user.mileageScore) + parseInt(mileageScore) : mileageScore
    });
    alert("Success: User info has been updated")
}


function Indiv_User({ user, user_id }) {
    const api_url = process.env.REACT_APP_FIREBASE_FUNCTION_URL_PRODUCTION

    getAuth()
    const db = getFirestore();
    const [editClicked, setEditClicked] = useState(false)
    const [courses, setCourses] = useState(null)
    const [addCourse, setAddCourse] = useState(false)

    const [eventDoesntExist, setEventDoesntExist] = useState(false)
    const [userPreviouslyInEvent, setUserPreviouslyInEvent] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)

    const customStyles = {
        content: {
            marginTop: '40px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderWidth: '0px',
            backgroundColor: 'white',
            boxShadow: '0px 4px 27px rgba(0, 0, 0, 0.25)',
            borderRadius: '20px',
            padding: '40px 60px'
        },
    };

    useEffect(() => {
        function_use_effect()
    }, [user])

    async function function_use_effect() {
        var courseIDs = []
        user?.courses?.map(i => courseIDs.push(i?.eventID))
        const courses = []
        if (courseIDs.length) {
            const eventRef = collection(db, 'Event')
            var q = query(eventRef, where('_id', 'in', courseIDs.slice(0, 10)), orderBy('startDateTime', 'desc'));
            var querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                courses.push(doc.data())
            });
            if (courseIDs.length > 10) {
                q = query(eventRef, where('startDateTime', '>=', new Date().toISOString()), where('_id', 'in', courseIDs.slice(10, 18)), orderBy("startDateTime"));
                querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    courses.push(doc.data())
                    console.log(doc.data())
                });
            }
        }
        const courses_final_temp = []
        courses.map((indiv_courses) => {
            var course = indiv_courses
            user.courses.map(indiv_primary_course => {
                if (indiv_primary_course.eventID == indiv_courses._id)
                    course = { ...indiv_courses, zoom_join_url: indiv_primary_course.zoom_join_url, zoom_registrant_id: indiv_primary_course.zoom_registrant_id }
            })
            courses_final_temp.push(course)
        })
        setCourses(courses_final_temp)

    }

    async function deleteCourseFromUser(id) {
        if (window.confirm('Are you sure you want delete this course from the user account?')) {
            // DELETE EVENT FROM USER
            const userCourseDoc = doc(db, "Users", user_id);
            const editedCourses = []
            user.courses?.map(i => {
                if (i.eventID != id)
                    editedCourses.push(i)
            })
            await updateDoc(userCourseDoc, {
                courses: editedCourses
            });

            // DELETE USER FROM EVENT
            const eventRef = doc(db, "Event", id);
            const eventDocSnap = await getDoc(eventRef);
            if (eventDocSnap.exists()) {
                const eventAttendees = []
                // to make sure the event still has in person attendees
                var stillHasInPerson = false
                eventDocSnap.data().attendees?.map(i => {
                    if (i._id != user_id) {
                        eventAttendees.push(i)
                        // to make sure the event still has in person attendees
                        if (i.inPerson)
                            stillHasInPerson = true
                    }
                })
                await updateDoc(eventRef, {
                    hasInPersonAttendee: stillHasInPerson,
                    attendees: eventAttendees
                });

                window.location.reload();
            }
        }
    }

    function printCourseRow(data, i) {
        var startDate = new Date(data.startDateTime)
        const month = startDate.getMonth() + 1
        const day = startDate.getDate()
        const year = startDate.getFullYear()

        var location = data.location
        var timeZone = data.zone

        var reg = /\d/;
        if (reg.test(data.location)) {
            location = 'N/A'
            timeZone = data.location
        }
        console.log(data)

        async function addToZoom() {
            const meeting_id = data.zoomMeetingID
            var response = 'fail'
            var registrant_id = ''
            var join_url = ''

            if (!meeting_id) {
                return 'no zoom meeting id for this event'
            }

            const res = await axios.post('/zooom_auth_token', {}, {
                headers: {
                    Authorization: 'Basic VWVHd0ZGSDhSWXlSb1ZING11R2hUdzozWWhZaTc2NXZmYW9JRlpjWngxeFl1bkZaU0U2TFpVZA==',
                    Host: 'zoom.us'
                }
            })
            const zoom_access_token = res?.data?.access_token ? res?.data?.access_token : null

            try {
                // This is the API on Next JS
                await axios.get('/api/get_meeting_info?meeting_id=' + meeting_id + '&zoom_access_token=' + zoom_access_token)
                    .then(async (res) => {
                        // Error in getting Zoom info
                        if (res.data.errorCode) {
                            alert('Unable to get Zoom meeting info')
                            window.location.reload();
                        }
                        // Successfully got Zoom info
                        else {
                            var already_registered = false
                            var zoom_join_url
                            var zoom_registrant_id
                            // Check if user in Zoom meeting
                            for (let i = 0; i < res.data.data.registrants.length; i++) {
                                const registrant = res.data.data.registrants[i]

                                if (registrant.email === user.email) {
                                    already_registered = true
                                    zoom_join_url = registrant.join_url
                                    zoom_registrant_id = registrant.id
                                }
                            }

                            const docRef = doc(db, "Users", user.uid);
                            const docSnap = await getDoc(docRef);

                            // If Already Registered, Check and Add Zoom Link To User
                            if (already_registered) {
                                if (!data.zoom_join_url) {
                                    const courses_temp = []
                                    user.courses.map((course) => {
                                        if (course.eventID === data._id)
                                            courses_temp.push({ ...course, zoom_join_url: zoom_join_url, zoom_registrant_id: zoom_registrant_id })
                                        else courses_temp.push(course)
                                    })

                                    // Update courses
                                    if (courses_temp.length) {
                                        await updateDoc(docRef, {
                                            courses: courses_temp
                                        });
                                        setModalOpen(true)
                                        setTimeout(function () {
                                            window.location.reload();
                                        }, 1000);
                                    }
                                } else {
                                    alert(user.email + ' has been previously added to Zoom and has Zoom link')
                                    window.location.reload();
                                }
                            }
                            // Add To Zoom if Not Added
                            else {
                                const [zoomRes, registrant_id, join_url] = await RegisterUserForZoomEvent({
                                    email: user.email,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    meeting_id: meeting_id
                                })

                                const courses_temp = []
                                // Add url to course
                                user.courses.map(course => {
                                    // Add Secondary Course
                                    if (course.eventID == data._id)
                                        courses_temp.push({ ...course, zoom_registrant_id: registrant_id, zoom_join_url: join_url })
                                    else courses_temp.push(course)
                                })

                                // All Successful
                                if (zoomRes == 'Success') {
                                    // Update User Courses With Zoom Link
                                    await updateDoc(docRef, {
                                        courses: courses_temp
                                    });

                                    setModalOpen(true)
                                    setTimeout(function () {
                                        window.location.reload();
                                    }, 1000);
                                } else if (zoomRes == 'no zoom meeting id') {
                                    alert(user.email + ' has been added and email sent but No Zoom Meeting has been set up for this event - ' + data.titleAbbrev)
                                    window.location.reload();
                                } else if (zoomRes == 'more than 3 attempts to register') {
                                    alert(user.email + ' has been added and email sent but can not add Zoom Registrant More Than 3 Times - ' + data.titleAbbrev)
                                    window.location.reload();
                                } else {
                                    alert(user.email + ' has been added and email sent but there has been an issue sending Zoom invite - ' + data.titleAbbrev)
                                    window.location.reload();
                                }
                            }
                        }
                        // if (!res.data.errorCode) {
                        //     response = 'Success'
                        //     registrant_id = res?.data?.registrant_id ? res?.data?.registrant_id : ''
                        //     join_url = res?.data?.join_url ? res?.data?.join_url : ''
                        // }
                        // else if (res.data.errorCode == '429')
                        //     response = 'more than 3 attempts to register'
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } catch (error) {
                console.log(error)
            }
        }

        return (
            <div className='individual_user_individual_course_data_top_clipboard_div'>
                <div className='individual_user_individual_course_data'>
                    <a href={'/admin/events/course?id=' + data._id} style={{ textDecoration: 'none' }}>
                        <div className={'Saira_Medium_DGray individual_user_individual_course_data_div_title admin_event_list_individual_course_div_' + i % 2}
                            style={{ cursor: 'pointer' }}>
                            <p>{data.titleAbbrev}</p>
                            <p>{month}/{day}/{year}</p>
                            <p>{data.virtual ? 'true' : 'false'}</p>
                            <p>{location}</p>
                            <p>{data.zoom_join_url ? 'Available' : ''}</p>
                        </div>
                    </a>
                </div>

                {/* <div>
                    <div className='individual_user_individual_course_add_zoom_div'>
                        <button onClick={addToZoom}>
                            <AiFillFileAdd size={20} />
                        </button>
                        <p className='Saira_Medium_LGray'>add to zoom</p>
                    </div>
                </div> */}

                {/* {
                    data.zoom_join_url ?
                        <div className='individual_user_individual_course_clipboard_div'>
                            <CopyToClipboard
                                text={data.zoom_join_url}
                                onCopy={() => alert("Copied Zoom Link")}
                            >
                                <button>
                                    <TiClipboard size={20} />
                                </button>
                            </CopyToClipboard>
                            <p className='Saira_Medium_LGray'>copy zoom</p>
                        </div>
                        : ''
                } */}
            </div>
        )
    }

    async function SubmitEventID() {
        const value = document.getElementById('individual_user_add_course_event_id_input').value
        const user_virtual = document.getElementById('admin_course_user_event_add_input_virtual').checked

        const eventRef = doc(db, "Event", value);
        const eventDocSnap = await getDoc(eventRef);
        if (eventDocSnap.exists()) {
            const eventAttendees = eventDocSnap.data().attendees ? eventDocSnap.data().attendees : []
            var userInEvent = false
            eventAttendees.map(i => {
                if (i._id == user_id)
                    userInEvent = true
            })
            if (!userInEvent) {
                eventAttendees.push({
                    _id: user_id,
                    name: user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.displayName,
                    email: user.email,
                    inPerson: !user_virtual
                })
                await updateDoc(eventRef, {
                    hasInPersonAttendee: !user_virtual ? true : eventDocSnap.data().hasInPersonAttendee ? eventDocSnap.data().hasInPersonAttendee : false,
                    attendees: eventAttendees
                });
            }

            const userCourseDoc = doc(db, "Users", user_id);
            userInEvent = false
            user.courses?.map(i => {
                if (i.eventID == value)
                    userInEvent = true
            })
            if (!userInEvent) {
                await updateDoc(userCourseDoc, {
                    courses: [...user.courses, { eventID: value }]
                });

                const name = user.firstName && user.lastName ? user.firstName + ' ' + user.lastName : user.displayName
                SuccessfulRegistration({ userEmail: user.email, userName: name, eventData: eventDocSnap.data() })

                window.location.reload();
            } else {
                setEventDoesntExist(false)
                setUserPreviouslyInEvent(true)
            }
        } else {
            setUserPreviouslyInEvent(false)
            setEventDoesntExist(true)
        }
    }

    function verifyUserEmail() {
        axios.post(api_url + '/verify_user_by_admin', { uid: user_id })
            .then((response) => {
                console.log(response)
                if (response.data == 'All Done!') {
                    alert('Verified!')

                    window.location.reload();
                } else {
                    alert(response?.data?.message)

                    window.location.reload();
                }
            })
            .catch(() => {
                alert('Error!')

                window.location.reload();
            })
    }

    function reset_user_password_by_admin() {
        let password = prompt("Enter New Password:");

        if (password)
            axios.post(api_url + '/reset_user_password_by_admin', { uid: user_id, password: password })
                .then((response) => {
                    console.log(response)
                    if (response.data == 'All Done!') {
                        alert('Password Updated!')

                        window.location.reload();
                    } else {
                        alert(response?.data?.message)

                        window.location.reload();
                    }
                })
                .catch(() => {
                    alert('Error!')

                    window.location.reload();
                })
    }

    async function restrict_exam_access() {
        const docRef = doc(db, "Users", user.uid);

        await updateDoc(docRef, {
            restricted_access_to_exam: user.restricted_access_to_exam === true ? false : true
        });
        window.location.reload();
    }


    async function restrict_book_access() {
        const docRef = doc(db, "Users", user.uid);

        await updateDoc(docRef, {
            restricted_access_to_book: user.restricted_access_to_book === true ? false : true
        });
        window.location.reload();
    }

    const [changeEmail, setChangeEmail] = useState()

    function changeEmailAddress() {
        const new_email = document.getElementById(`user_email_${user?.email}`).value;

        console.log({ uid: user_id, new_email: new_email, emailVerified: true })
        axios.post(api_url + '/update_user_email', { uid: user_id, new_email: new_email, emailVerified: true })
            .then((response) => {
                if (response.data.message == 'User email updated successfully') {
                    alert('Updated!')
                    window.location.reload();
                } else {
                    alert(response?.data?.message)
                    window.location.reload();
                }
            })
            .catch(() => {
                alert('Error!')
                window.location.reload();
            })
    }

    return (
        <div className='all_div_margin' style={{ marginTop: "150px" }}>
            <Modal
                isOpen={modalOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ textAlign: 'center', color: 'green', padding: '50px' }}>
                    <div>
                        <BsCheck2Circle size={60} />
                    </div>
                    <h2 className='Saira_Bold_Black'>All Done!</h2>
                </div>
            </Modal>

            <a href='/admin' className='Saira_Bold_Red' style={{ textDecoration: "none" }}>
                {"Admin Pannel"}
            </a>
            <div className='Indiv_User_User_Detail_Whole_Div'>
                <div className='Indiv_User_User_Detail_Top_Pic_And_Name'>
                    <div style={{ width: "100%", maxWidth: "200px", minWidth: "150px" }}>
                        {
                            user?.photoURL != null ?
                                <img src={user.photoURL} alt="User Profile Picture" className='Dashboard_Left_Pannel_Profile_Pic' width={"100%"} /> :
                                <img src={testImg} alt="User Profile Picture" className='Dashboard_Left_Pannel_Profile_Pic' width='150px' height='150px' />
                        }
                    </div>
                    <h1 className='Saira_Regular_Black'>{user?.firstName ? user?.lastName ? (user?.firstName + " " + user.lastName) : user?.displayName : user?.displayName}</h1>


                    <button style={{
                        display: editClicked ? 'none' : 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer',
                        width: 'fit-content', backgroundColor: 'transparent', borderWidth: '0px'
                    }}
                        onClick={e => setEditClicked(true)}>
                        <p style={{ fontSize: '20px' }} className='Saira_Medium_Black'>Edit</p>
                        <MdEdit size='22' />
                    </button>
                    <button style={{
                        display: editClicked ? 'flex' : 'none', alignItems: 'center', gap: '10px', cursor: 'pointer',
                        width: 'fit-content', backgroundColor: 'transparent', borderWidth: '0px'
                    }}
                        onClick={e => { setEditClicked(false); setAddCourse(false); setUserPreviouslyInEvent(false); setEventDoesntExist(false) }}>
                        <p style={{ fontSize: '20px' }} className='Saira_Medium_Black'>Cancel</p>
                        <ImCancelCircle size='22' />
                    </button>


                    <input className='Saira_Bold_White data-center-contact-us-form-submit-button'
                        type="submit"
                        hidden={!editClicked}
                        id={`user_submit_${user?.email}`}
                        value={"Save Changes"}
                        onClick={() => saveChanges(user, user_id)}
                    />

                    {/* <input type={"submit"} className='Saira_Bold_White data-center-contact-us-form-submit-button' value={"Edit"} id={`user_edit_${user?.email}`}
                        onClick={() => editPressed(user)} /> */}
                </div>
                <div className='Indiv_User_User_Detail_user_info_button'>
                    <p className='Saira_Regular_Gray'> {user?.uid}</p>
                    <div className='Indiv_User_users_info_list Saira_Regular_Black'>
                        <label className='idca_admin_label'>
                            Email:
                            <input disabled={!changeEmail} className='StandardsDownload_Form_input' type="text" id={`user_email_${user?.email}`} defaultValue={user?.email} />
                        </label>
                        <div>
                            {changeEmail ? <div>
                                <button onClick={changeEmailAddress}>
                                    Save
                                </button>
                                <br />
                                <button onClick={() => setChangeEmail(false)}>
                                    Cancel
                                </button>
                            </div> : <button onClick={() => setChangeEmail(true)}>
                                Change Email
                            </button>}
                        </div>
                        <label className='idca_admin_label'>
                            Phone:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_phone_${user?.email}`} defaultValue={user?.phone} />
                        </label>
                        <label className='idca_admin_label'>
                            Title:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_title_${user?.email}`} defaultValue={user?.title} />
                        </label >
                        <label className='idca_admin_label'>
                            Company:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_company_${user?.email}`} defaultValue={user?.company} />
                        </label>
                        <label className='idca_admin_label'>
                            Street Address:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_street_${user?.email}`} defaultValue={user?.address} />
                        </label>
                        <label className='idca_admin_label'>
                            City:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_city_${user?.email}`} defaultValue={user?.city} />
                        </label>
                        <label className='idca_admin_label'>
                            Zipcode:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_zipcode_${user?.email}`} defaultValue={user?.zipcode} />
                        </label>
                        <label className='idca_admin_label'>
                            State:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_state_${user?.email}`} defaultValue={user?.state} />
                        </label>
                        <label className='idca_admin_label'>
                            Country:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_country_${user?.email}`} defaultValue={user?.country} />
                        </label>
                        <label className='idca_admin_label'>
                            News Subscribed:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="checkbox" id={`user_news_subscribed_${user?.email}`} defaultValue={user?.newsSubscribed} />
                        </label>
                        <label className='idca_admin_label'>
                            Add
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_mileageScore_${user?.email}`} />
                            Mileage Points
                        </label>
                        <label className='idca_admin_label'>
                            Set Mileage Score To:
                            <input disabled={!editClicked} className='StandardsDownload_Form_input' type="text" id={`user_mileageScore_set_${user?.email}`} defaultValue={user?.mileageScore} />
                        </label>
                        <label className='idca_admin_label'>
                            Verify Email
                            <button onClick={verifyUserEmail}>Verify</button>
                        </label>
                        <label className='idca_admin_label'>
                            Change Password
                            <button onClick={reset_user_password_by_admin}>Reset Password</button>
                        </label>
                        <label className='idca_admin_label'>
                            Restrict Access To Exam
                            <button onClick={restrict_exam_access}>{user?.restricted_access_to_exam ? 'Activate' : 'Restrict'}</button>
                        </label>
                        <label className='idca_admin_label'>
                            Restrict Access To Course Book
                            <button onClick={restrict_book_access}>{user?.restricted_access_to_book ? 'Activate' : 'Restrict'}</button>
                        </label>
                    </div>

                    <div className='Saira_Medium_Black' style={{ width: '100%', marginTop: '40px' }}>
                        <h2>Courses</h2>
                        <div className='individual_user_individual_course_data'>
                            <div className='individual_user_individual_course_data_div_title'>
                                <h3>Course</h3>
                                <h3>Start Date</h3>
                                <h3>Virtual</h3>
                                <h3>Location</h3>
                                <h3>Zoom</h3>
                            </div>
                        </div>

                        {courses && courses.map((data, i) => printCourseRow(data, i))}

                        <div style={{ display: editClicked && addCourse ? 'inherit' : 'none' }}>
                            <label className='idca_admin_label'>
                                Event ID:
                                <input id='individual_user_add_course_event_id_input' />
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <h4>Virtual</h4>
                                    <input id='admin_course_user_event_add_input_virtual' type="checkbox" defaultChecked={true} />
                                </div>
                                <button onClick={() => SubmitEventID()}>submit</button>
                            </label>
                        </div>

                        <center style={{ display: userPreviouslyInEvent ? 'inherit' : 'none' }}>
                            <p className='Saira_Medium_Red'>user is already in this event</p>
                        </center>

                        <center style={{ display: eventDoesntExist ? 'inherit' : 'none' }}>
                            <p className='Saira_Medium_Red'>event doesn&apos;t exist</p>
                        </center>

                        {/* <center id='individual_user_add_course_button_center' style={{ display: !editClicked ? 'none' : 'inherit', marginTop: '20px' }}>
                            <button onClick={e => setAddCourse(true)}>
                                <IoMdAdd style={{ position: 'relative', top: '2px' }} /> Add
                            </button>
                        </center> */}

                    </div>
                </div>
            </div >
        </div >

    );

}


export default Indiv_User

