import React, { useEffect, useState } from 'react';
import Certifications from '../UserDashboard/Home/Certifications/Certifications';
import NextStep from '../UserDashboard/Home/NextStep/NextStep';
import UserPortalEmailSignature from '../UserPortalCourse/EmailSignature/EmailSignature';
import UserPortalLinkedIn from '../UserPortalCourse/LinkedinJoin/LinkedinJoin';
import UserPortalSocial from '../UserPortalCourse/Social/Social';
import UserPortalCourseMaterial from './CourseMaterial/CourseMaterial';
import UserPortalPreviousCourses from './PreviousCourses/PreviousCourses';
import UserPortalUpcomingCourses from './UpcomingCourses/UpcomingCourses';

function UserPortalAllCourses({ userInfo, courses, certificates, previousCourses }) {

    return (
        <div className='user_dashboard_home_top_div'>
            <div className='user_dashboard_home_main_div'>
                <UserPortalUpcomingCourses courses={courses ? courses : []} />
                {!userInfo?.restricted_access_to_book ? <UserPortalCourseMaterial userInfo={userInfo} certificates={certificates} courses={courses} previousCourses={previousCourses} /> : null}
                <NextStep certificates={certificates} courses={courses} />
                <UserPortalPreviousCourses previousCourses={previousCourses ? previousCourses : []} />
                {(certificates && certificates.length) ? <Certifications certificates={certificates} /> : null}
            </div>

            <div className='user_dashboard_home_right_div'>
                {(certificates && certificates.length) ? <UserPortalEmailSignature certificates={certificates} /> : null}
                <UserPortalLinkedIn />
                <UserPortalSocial />
            </div>
        </div>
    );
}

export default UserPortalAllCourses