import React, { useState, useEffect } from 'react';
import courseJson from '../../../../components/Pages/All_Courses_Detail.json'
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { BsFiletypePdf } from "react-icons/bs";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function UserPortalCourseMaterial({ userInfo, certificates, courses, previousCourses, pendingCourses }) {
    const [courseMaterial, setCourseMaterial] = useState()
    const [allCourses, setAllCourses] = useState([])
    const [courseView, setCourseView] = useState()
    const [pass_visible, setPass_visible] = useState([])

    // Create a reference with an initial file path and name
    const storage = getStorage();

    function downloadPDF(course) {
        getDownloadURL(ref(storage, `gs://idca-f9873.appspot.com/CourseMaterial/${course.course_book_pdf}`))
            .then((url) => {
                console.log(url)
                window.open(url)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        const allCourses = []
        const allCourseMaterial = []
        const uniqueCourseMaterial = []

        certificates?.map(cert => allCourses.push(cert?.group_name?.replace('®', '')))
        courses?.map(course => allCourses.push(course?.titleAbbrev))
        previousCourses?.map(course => allCourses.push(course?.titleAbbrev))
        pendingCourses?.map(course => allCourses.push(course?.event_title_abbrev))


        allCourses.map(course => {
            if (course === 'DCA')
                allCourseMaterial.push(...['DCIS', 'DCES', 'DCOS', 'DCTP'])
            else if (course === 'DCM')
                allCourseMaterial.push(...['DCIS', 'DCOS', 'DCTP'])
            else if (course === 'DCE')
                allCourseMaterial.push(...['DCIS', 'DCES', 'DCTP'])
            else if (course === 'DCTP')
                allCourseMaterial.push(...['DCTP'])
            else if (course === 'DCIE')
                allCourseMaterial.push(...['DCIS', 'DCES'])
            else if (course === 'DCOM')
                allCourseMaterial.push(...['DCIS', 'DCOS'])
            else if (course === 'DCES')
                allCourseMaterial.push(...['DCES'])
            else if (course === 'DCOS')
                allCourseMaterial.push(...['DCOS'])
            else if (course === 'DCIS')
                allCourseMaterial.push(...['DCIS'])
            else if (course === 'DCRP')
                allCourseMaterial.push(...['DCRP'])
        })
        uniqueCourseMaterial.push(...new Set(allCourseMaterial));
        setAllCourses(uniqueCourseMaterial)
    }, [certificates, courses, previousCourses, pendingCourses])


    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }

    function certArray(windowSize) {
        const main_cert = []
        var secondary_cert = []
        const pass_view_main_tmp = []
        var pass_view_tmp = []
        var num_per_slide = 3

        // Set number of certs in a single carousel div
        if (windowSize > 1800) {
            num_per_slide = 4
        } else if (windowSize > 1200) {
            num_per_slide = 3
        } else if (windowSize > 600) {
            num_per_slide = 2
        } else {
            num_per_slide = 1
        }
        setCourseView(Array(num_per_slide).fill('#006197'))

        // Set cert array of array with the num_per_slide
        allCourses.map((cert, i) => {
            // certs to be included in a single slide
            if (i == 0 || (i % num_per_slide) != 0) {
                secondary_cert.push(getCourseJson(cert))
                pass_view_tmp.push(false)
                if ((i + 1) == allCourses.length) {
                    main_cert.push(secondary_cert)
                    pass_view_main_tmp.push(pass_view_tmp)
                }
            } else {
                // add all certs in one slide as a single array and start new slide
                main_cert.push(secondary_cert)
                secondary_cert = []
                pass_view_main_tmp.push(pass_view_tmp)
                pass_view_tmp = []
                secondary_cert.push(getCourseJson(cert))
                pass_view_tmp.push(false)
                if ((i + 1) == allCourses.length) {
                    main_cert.push(secondary_cert)
                    pass_view_main_tmp.push(pass_view_tmp)
                }
            }
        })

        // Set certLists
        setCourseMaterial(main_cert)
        // Pass view
        setPass_visible(pass_view_main_tmp)
    }

    useEffect(() => {
        // Set Cert double array
        certArray(window.innerWidth)
    }, [allCourses])

    useEffect(() => {
        const handleResize = () => {
            // Set Cert double array
            certArray(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [allCourses]); // Empty dependency array means this effect only runs once after the component mounts


    function changeHoverStateLeave(i, ind) {
        document.getElementById('download_material_' + ind + '_' + i).style.backgroundColor = 'white'
        document.getElementById('download_material_p_' + ind + '_' + i).style.color = '#006197'
        const tmp = []
        courseView.map((view, ind) => {
            if (i !== ind)
                tmp.push(view)
            else
                tmp.push('#006197')
        })
        setCourseView(tmp)
    }

    function changeHoverStateEnter(i, ind) {
        document.getElementById('download_material_' + ind + '_' + i).style.backgroundColor = '#006197'
        document.getElementById('download_material_p_' + ind + '_' + i).style.color = 'white'
        const tmp = []
        courseView.map((view, ind) => {
            if (i !== ind)
                tmp.push(view)
            else
                tmp.push('white')
        })
        setCourseView(tmp)
    }

    function copyToClipboard() {
        const message = document.getElementById('clipboard_copy_message');
        message.style.display = 'block';
        setTimeout(() => {
            message.style.display = 'none';
        }, 2000); // Hide message after 2 seconds
    }

    function changePassView(indiv, i) {
        const main = []
        var second = []
        pass_visible.map((pass_arr, j) => {
            pass_arr.map((pass, k) => {
                if (indiv === j && i === k)
                    second.push(!pass_visible[j][k])
                else
                    second.push(pass)
            })
            main.push(second)
            second = []
        })
        setPass_visible(main)
    }

    return (
        <div className='user_dashboard_home_profile_div' style={{ paddingBottom: '0px' }}>
            <div>
                <p className='user_dashboard_home_journey_title'>Course Materials</p>
            </div>

            <div id="clipboard_copy_message" className="clipboard_copy_message_hidden">Copied to clipboard</div>

            {(!userInfo?.restricted_access_to_book && courseMaterial && courseMaterial.length > 0) ?
                <div className='user_portal_all_courses_upcoming_classes_all_div'>
                    {/* <Carousel showStatus={false}> */}
                    {courseMaterial.map((courses_list, ind) => (
                        <div className='user_portal_all_courses_upcoming_classes_carousel_page_div' key={ind} style={{ paddingBottom: '15px' }}>
                            {courses_list.map((course, i) => (
                                <div key={i} className='user_portal_all_course_material_indiv_div'>
                                    <div className='user_portal_all_courses_upcoming_classes_indiv_img__title_div'>
                                        <img src={course.book_img} className='user_portal_all_course_material_indiv_img_div' />
                                    </div>

                                    {(pass_visible[ind] && (pass_visible[ind][i] === false || pass_visible[ind][i])) ?
                                        <div className="user_portal_indiv_course_exam_detail_div user_portal_course_book_large_password_inner_div">
                                            {pass_visible[ind][i] ?
                                                <p style={{ width: '80%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{course.book_pass}</p>
                                                : <div style={{ width: '80%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className="user_portal_course_book_large_password_hidden_div">
                                                    {course.book_pass.split("").map((char, i) => (
                                                        <div key={i}>
                                                            <GoDotFill size={10} />
                                                        </div>
                                                    ))}
                                                </div>}


                                            <div className="user_portal_course_book_large_password_action_div">
                                                <div onClick={() => changePassView(ind, i)}>
                                                    {pass_visible[ind][i] ?
                                                        <IoEyeOff />
                                                        : <IoEye />}
                                                </div>

                                                <CopyToClipboard text={course.book_pass}
                                                    onCopy={() => copyToClipboard()}>
                                                    <MdContentCopy />
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        : null}

                                    <div className='user_dashboard_home_certifications_indiv_cert_download'
                                        id={'download_material_' + ind + '_' + i}
                                        onClick={() => downloadPDF(course)}
                                        onMouseEnter={() => changeHoverStateEnter(i, ind)}
                                        onMouseLeave={() => changeHoverStateLeave(i, ind)}>
                                        <BsFiletypePdf size={17} color={courseView ? courseView[i] : null} />
                                        <p id={'download_material_p_' + ind + '_' + i}>Download</p>
                                    </div>

                                    <p>{course.full_title}®</p>
                                </div>
                            ))}
                        </div>
                    ))}
                    {/* </Carousel> */}
                </div>
                : <div>
                    <p>You do not have any available course material</p>
                </div>}
        </div>
    );
}

export default UserPortalCourseMaterial
