import axios from "axios";
import courseJson from '../../../components/Pages/All_Courses_Detail.json'


export default async function addAttendeeToEventModule(firstName, lastName, email, phone, secondPhone, company, title, password, selectedUser,
    event, attendeeVirtual, restrictExamAccess, paymentType, address, apt, city, state, zipcode, country) {

    const api_url = process.env.REACT_APP_FIREBASE_FUNCTION_URL_PRODUCTION

    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }
    const course_json_arr = []
    switch (event.titleAbbrev) {
        case "DCIS":
            course_json_arr.push(...[getCourseJson('DCIS')])
            break;
        case "DCES":
            course_json_arr.push(...[getCourseJson('DCES')])
            break;
        case "DCOS":
            course_json_arr.push(...[getCourseJson('DCOS')])
            break;
        case "DCIE":
            course_json_arr.push(...[getCourseJson('DCIS'), getCourseJson('DCES')])
            break;
        case "DCOM":
            course_json_arr.push(...[getCourseJson('DCIS'), getCourseJson('DCOS')])
            break;
        case "DCTP":
            course_json_arr.push(...[getCourseJson('DCTP')])
            break;
        case "DCRP":
            course_json_arr.push(...[getCourseJson('DCRP')])
            break;
        default:
            break;
    }

    // Create or Update User
    const create_update_res = await create_update_user_helper(address, apt, city, state, zipcode, country);
    console.log(create_update_res)
    if (create_update_res.status === 200) {
        // Add user to event
        const final_result = await delayedExecution()
        console.log(final_result)
        return final_result
    } else {
        console.log(create_update_res.message);
        return create_update_res.message
    }

    function delayedExecution() {
        return new Promise(async (resolve) => {
            // setTimeout(async () => {
            const add_to_event_res = await add_user_to_event_helper(create_update_res.uid)
            console.log(add_to_event_res)
            if (add_to_event_res === 200) {
                // Send registratoin confirmation email
                const send_confirmation_email_res = await send_confirmation_email_helper()
                if (send_confirmation_email_res === 200) {
                    // Send couse book
                    // If DCIE or DCOM send two emails otherwise send one course book
                    if (course_json_arr.length == 2) {
                        const send_course_book_email_one_res = await send_course_book_helper(course_json_arr[0])
                        if (send_course_book_email_one_res === 200) {
                            const send_course_book_email_two_res = await send_course_book_helper(course_json_arr[1])
                            if (send_course_book_email_two_res === 200) {
                                resolve('All Done!')
                            } else {
                                console.log('Failed to send course book ' + course_json_arr[1].title);
                                resolve('Error sending course book ' + course_json_arr[1].title)
                            }
                        } else {
                            console.log('Failed to send course book ' + course_json_arr[0].title);
                            resolve('Error sending course book ' + course_json_arr[0].title)
                        }
                    } else if (course_json_arr.length == 1) {
                        const send_course_book_email_res = await send_course_book_helper(course_json_arr[0])
                        if (send_course_book_email_res === 200) {
                            resolve('All Done!')
                        } else {
                            console.log('Failed to send course book');
                            resolve('Error sending course book')
                        }
                    }
                } else {
                    console.log('Failed to send confirmation email');
                    resolve('Error sending confirmation email')
                }
            } else {
                console.log('Failed to add user to events');
                resolve('Error adding attendee to events')
            }
            // }, 1000);
        });
    }


    function send_course_book_helper(course_json) {
        return new Promise((resolve, reject) => {
            axios.post(api_url + '/course_material_email', {
                name: firstName,
                email: email,
                event_name: course_json.full_title,
                event_name_abbrev: course_json.title,
                book_link: course_json.book_link,
                book_img: 'https://idc-a.org' + course_json.book_img,
                book_pass: course_json.book_pass,
            }).then((response) => {
                console.log(response)
                resolve(200);
            }).catch((error) => {
                console.log(error)
                resolve(400);
            })
        })
    }

    function send_confirmation_email_helper() {
        return new Promise((resolve, reject) => {
            axios.post(api_url + '/user_course_registration_confirmation', {
                name: firstName,
                email: email,
                event_name: event.title,
                event_date: dateToString(event.startDateTime),
                event_time: (event.titleAbbrev == 'DCRP' ? (event.time.full + ' ') : "9:00am ") + timezoneToLocation(event.zone),
                virtual: attendeeVirtual,
                newAccount: selectedUser ? false : true,
                password: password ? password : null
            }).then((response) => {
                console.log(response)
                resolve(200);
            }).catch((error) => {
                console.log(error)
                resolve(400);
            })
        })

        function dateToString(date) {
            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var course_days = 1
            if (event.titleAbbrev === 'DCES' || event.titleAbbrev === 'DCOS')
                course_days = 2
            else if (event.titleAbbrev === 'DCIE' || event.titleAbbrev === 'DCOM')
                course_days = 4
            else if (event.titleAbbrev === 'DCRP')
                course_days = 0

            const date_temp = new Date(date)
            const end_date_temp = new Date(date_temp)
            end_date_temp.setDate(date_temp.getDate() + course_days)

            var date_string
            if (event.titleAbbrev === 'DCRP') {
                date_string = month[date_temp.getMonth()] + ' ' + date_temp.getDate() + ', ' + end_date_temp.getFullYear()
            } else {
                date_string = month[date_temp.getMonth()] + ' ' + date_temp.getDate() + ' to ' + month[end_date_temp.getMonth()] + ' ' + end_date_temp.getDate() + ', ' + end_date_temp.getFullYear()
            }

            return date_string
        }

        function timezoneToLocation(timezone) {
            var location
            if (timezone == '-4')
                location = 'EST'
            else if (timezone == '-5')
                location = 'CST'
            else if (timezone == '-6')
                location = 'MST'
            else if (timezone == '-7')
                location = 'PST'
            else if (timezone == '-8')
                location = 'AKST'
            else if (timezone == '-9')
                location = 'HST'
            else
                location = 'GMT' + timezone
            return location
        }
    }

    function add_user_to_event_helper(uid) {
        return new Promise((resolve, reject) => {
            axios.post(api_url + '/add_user_to_event', {
                uid: uid,
                eventID: event._id,
                user_virtual: attendeeVirtual,
                paymentType: paymentType,
            }).then((response) => {
                console.log(response)
                resolve(200);
            }).catch((error) => {
                console.log(error)
                resolve(400);
            })
        })
    }

    function create_update_user_helper(address, apt, city, state, zipcode, country) {
        return new Promise((resolve, reject) => {
            if (selectedUser) {
                // Update User
                axios.post(api_url + '/update_user', {
                    uid: selectedUser.uid, phone: phone, mobile: secondPhone,
                    firstName: firstName, lastName: lastName, company: company, title: title,
                    address: address, apt: apt, city: city, state: state, zipcode: zipcode, country: country,
                    restrictExamAccess: restrictExamAccess
                }).then((response) => {
                    // Updated Successfully
                    console.log(response)
                    resolve({ status: 200, uid: selectedUser.uid });
                }).catch((error) => {
                    // Error Updating
                    console.log(error)
                    resolve({ status: 400, message: 'Error updating user' });
                })
            } else {
                // Create User
                axios.post(api_url + '/create_user', {
                    email: email, password: password, phone: phone, mobile: secondPhone,
                    firstName: firstName, lastName: lastName, company: company, title: title,
                    address: address, apt: apt, city: city, state: state, zipcode: zipcode, country: country,
                    restrictExamAccess: restrictExamAccess,
                    emailVerified: true, signedInBy: 'Admin'
                }).then((response) => {
                    // User Created Successfully
                    console.log(response)
                    resolve({ status: 200, uid: response.data.user.uid });
                }).catch((error) => {
                    // Error Creating User
                    console.log(error)
                    resolve({ status: 400, message: 'Error creating' });
                })
            }
        })
    }
}