import React, { useEffect, useState } from 'react';
import { CiCalendar } from "react-icons/ci";
import { SlLocationPin } from "react-icons/sl";
import { TfiNewWindow } from "react-icons/tfi";
import courseJson from '../../../../components/Pages/All_Courses_Detail.json'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function UserPortalUpcomingCourses({ courses }) {
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [courseView, setCourseView] = useState()
    const [coursesDoubleArray, setCoursesDoubleArray] = useState()

    function getStringDate(uo_date) {
        const date = new Date(uo_date)
        const month_str = month[date.getMonth()]
        const day_str = date.getDate()
        const day_ordinal_str = ((day_str == 1 || day_str == 21 || day_str == 31) ? 'st'
            : (day_str == 2 || day_str == 22) ? 'nd' : (day_str == 3) ? 'rd' : 'th')
        const year_str = date.getFullYear()
        var return_date = month_str + ' ' + day_str + day_ordinal_str + ', ' + year_str
        return return_date
    }


    function changeHoverStateLeave(i, ind) {
        document.getElementById('view_course_' + ind + '_' + i).style.backgroundColor = 'white'
        document.getElementById('view_course_p_' + ind + '_' + i).style.color = '#006197'
        const tmp = []
        courseView.map((view, ind) => {
            if (i !== ind)
                tmp.push(view)
            else
                tmp.push('#006197')
        })
        setCourseView(tmp)
    }

    function changeHoverStateEnter(i, ind) {
        document.getElementById('view_course_' + ind + '_' + i).style.backgroundColor = '#006197'
        document.getElementById('view_course_p_' + ind + '_' + i).style.color = 'white'
        const tmp = []
        courseView.map((view, ind) => {
            if (i !== ind)
                tmp.push(view)
            else
                tmp.push('white')
        })
        setCourseView(tmp)
    }


    function getCourseJson(courseTitleAbbrev) {
        let course;
        courseJson.map(iCourse => {
            if (iCourse.title == courseTitleAbbrev)
                course = iCourse
        })
        return course
    }

    function certArray(windowSize) {
        const main_cert = []
        var secondary_cert = []
        var num_per_slide = 3

        // Set number of certs in a single carousel div
        if (windowSize > 1600) {
            num_per_slide = 3
        } else if (windowSize > 900) {
            num_per_slide = 2
        } else {
            num_per_slide = 1
        }
        setCourseView(Array(num_per_slide).fill('#006197'))


        // Set cert array of array with the num_per_slide
        courses.map((cert, i) => {
            // certs to be included in a single slide
            if (i == 0 || (i % num_per_slide) != 0) {
                secondary_cert.push(cert)
                if ((i + 1) == courses.length) {
                    main_cert.push(secondary_cert)
                }
            } else {
                // add all certs in one slide as a single array and start new slide
                main_cert.push(secondary_cert)
                secondary_cert = []
                secondary_cert.push(cert)
                if ((i + 1) == courses.length) {
                    main_cert.push(secondary_cert)
                }
            }
        })

        // Set certLists
        setCoursesDoubleArray(main_cert)
    }


    useEffect(() => {
        // Set Cert double array
        certArray(window.innerWidth)
    }, [courses])

    useEffect(() => {
        const handleResize = () => {
            // Set Cert double array
            certArray(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [courses]); // Empty dependency array means this effect only runs once after the component mounts

    return (
        <div className='user_dashboard_home_profile_div' style={{ paddingBottom: '0px' }}>
            <div>
                <p className='user_dashboard_home_journey_title'>Your Upcoming Classes</p>
            </div>
            {(coursesDoubleArray && coursesDoubleArray.length > 0) ?
                <div className='user_portal_all_courses_upcoming_classes_all_div'>
                    {coursesDoubleArray.map((courses_list, ind) => (
                        <div className='user_portal_all_courses_upcoming_classes_carousel_page_div' key={ind}>
                            {courses_list.map((course, i) => (
                                <div key={i} className='user_portal_all_courses_upcoming_classes_indiv_div'>
                                    <div className='user_portal_all_courses_upcoming_classes_indiv_img__title_div'>
                                        <div>
                                            <img src={getCourseJson(course.titleAbbrev).badge_img_2024} className='user_portal_all_courses_upcoming_classes_indiv_img_div' />
                                        </div>
                                        <p>{course.title}</p>
                                    </div>

                                    <div className='user_portal_all_courses_upcoming_classes_indiv_all_detail_div'>
                                        <div className='user_portal_all_courses_upcoming_classes_indiv_detail_div'>
                                            <CiCalendar color='#006197' size={22} />
                                            <p>{getStringDate(course.startDateTime)}</p>
                                        </div>
                                        <div className='user_portal_all_courses_upcoming_classes_indiv_detail_div'>
                                            <SlLocationPin color='#006197' size={20} />
                                            <p>{course.location}</p>
                                        </div>
                                    </div>

                                    <a href={'/course?_id=' + course._id} style={{ textDecoration: 'none' }}>
                                        <div className='user_dashboard_home_certifications_indiv_cert_download' id={'view_course_' + ind + '_' + i}
                                            onMouseEnter={() => changeHoverStateEnter(i, ind)}
                                            onMouseLeave={() => changeHoverStateLeave(i, ind)}>
                                            <TfiNewWindow size={17} color={courseView ? courseView[i] : null} />
                                            <p id={'view_course_p_' + ind + '_' + i}>View</p>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                : <div>
                    <p>You are currently not registered for any courses</p>
                </div>}
        </div>
    );
}

export default UserPortalUpcomingCourses
