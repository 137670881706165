import React, { useState, useEffect } from 'react';
import CourseJourney from './CourseJourney/CourseJourney';
import ProfileDetail from './ProfileDetail/ProfileDetail';
import { getFirestore, doc, query, where, orderBy, collection, getDocs, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import Certifications from './Certifications/Certifications';
import UpcomingClasses from './UpcomingClasses/UpcomingClasses';
import NextStep from './NextStep/NextStep';
import UserPortalEmailSignature from '../../UserPortalCourse/EmailSignature/EmailSignature';
import UserPortalLinkedIn from '../../UserPortalCourse/LinkedinJoin/LinkedinJoin';
import UserPortalSocial from '../../UserPortalCourse/Social/Social';
import UserPortalCourseMaterial from '../../UserPortalAllCourses/CourseMaterial/CourseMaterial';

function UserDashboardHome() {

    const [user, setUser] = useState()
    const [userInfo, setUserInfo] = useState()
    const [certificates, setCertificates] = useState()
    const [courses, setCourses] = useState()
    const [pendingCourses, setPendingCourses] = useState()
    const [userType, setUserType] = useState()
    const [uploadStarted, setUploadStarted] = useState(false)
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
    const [previousCourses, setPreviousCourses] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // set user
                setUser(user)

                // get certifications
                var request = new XMLHttpRequest();
                request.open('POST', 'https://api.accredible.com/v1/credentials/search');
                request.setRequestHeader('Content-Type', 'application/json');
                request.setRequestHeader('Authorization', 'Token token=54dc431a507f5f49a059b8b5d1f96427');
                request.onreadystatechange = function () {
                    if (this.readyState === 4) {
                        var credentials_temp = JSON.parse(this.responseText).credentials
                        var cert_list = []
                        credentials_temp.map((cred) => {
                            const cred_name = cred.group_name.replace('®', '')
                            if (cred_name == 'DCIS' || cred_name == 'DCES'
                                || cred_name == 'DCOS' || cred_name == 'DCIE'
                                || cred_name == 'DCOM' || cred_name == 'DCTP'
                                || cred_name == 'DCM' || cred_name == 'DCE'
                                || cred_name == 'DCA')
                                cert_list.push(cred)
                        })
                        setCertificates(cert_list)
                    } else setCertificates([])
                };
                var body = {
                    'recipient': {
                        'email': user?.email
                    }
                };
                request.send(JSON.stringify(body));

                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    if (docSnap.data().idcaRole?.includes("corporate-member-admin")) {
                        setUserType("corporate-member-admin")
                    } else if (docSnap.data().idcaRole?.includes("admin") || docSnap.data().idcaRole?.includes("member")) {
                        setUserType("member")
                    } else {
                        setUserType("user")
                    }


                    setUserInfo(docSnap.data())
                    var all_courses = []
                    var all_event_id = []
                    docSnap.data().courses?.map(i => all_event_id.push(i.eventID))

                    const now = new Date()
                    const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);


                    if (all_event_id.length > 0) {
                        const eventRef = collection(db, "Event");
                        var q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(0, 10)), orderBy("startDateTime"));
                        var querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            if (doc.data().type == 'Training')
                                all_courses.push(doc.data())
                        });
                        if (all_event_id.length > 10) {
                            q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(10, 18)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                if (doc.data().type == 'Training')
                                    all_courses.push(doc.data())
                            });
                        }
                        if (all_event_id.length > 18) {
                            q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(18, 30)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                if (doc.data().type == 'Training')
                                    all_courses.push(doc.data())
                            });
                        }
                    }
                    setCourses(all_courses)


                    const prev_courses = []
                    if (all_event_id.length > 0) {
                        const eventRef = collection(db, "Event");
                        var q = query(eventRef, where('startDateTime', '<', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(0, 10)), orderBy("startDateTime"));
                        var querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            if (doc.data().type == 'Training')
                                prev_courses.push(doc.data())
                        });
                        if (all_event_id.length > 10) {
                            q = query(eventRef, where('startDateTime', '<', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(10, 18)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                if (doc.data().type == 'Training')
                                    prev_courses.push(doc.data())
                            });
                        }
                    }
                    setPreviousCourses(prev_courses)


                    // Pending Pot Courses
                    const pending_tmp = []
                    const pendingRef = collection(db, "Pending Pot");
                    var q = query(pendingRef, where('uid', '==', user.uid));
                    var querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        pending_tmp.push(doc.data())
                    });
                    setPendingCourses(pending_tmp)
                } else {
                    // doc.data() will be undefined in this case
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, [])

    return (
        <div className='user_dashboard_home_top_div'>
            {(userInfo && certificates) ?
                <div className='user_dashboard_home_main_div'>
                    <ProfileDetail user_info_param={userInfo} certificates_param={certificates} />
                    <CourseJourney certificates={certificates} courses={courses} />
                    <Certifications certificates={certificates} />
                    {(courses && courses.length) ? <UpcomingClasses courses={courses} email={userInfo.email} /> : null}

                    {!userInfo?.restricted_access_to_book ? <UserPortalCourseMaterial userInfo={userInfo} certificates={certificates} courses={courses} previousCourses={previousCourses} pendingCourses={pendingCourses} /> : null}

                    {/* NEED TO FIX COURSE SUGGESTION FOR DCA CERTIFIED PEOPLE !!!!!! */}
                    <NextStep certificates={certificates} courses={courses} />
                </div> :
                <div className="payment-course-before-load-spinner-container" style={{ marginTop: '150px', marginBottom: '150px' }}>
                    <div className="payment-course-before-loading-spinner">
                    </div>
                </div>}


            <div className='user_dashboard_home_right_div'>
                {(certificates && certificates.length) ? <UserPortalEmailSignature certificates={certificates} /> : null}
                <UserPortalLinkedIn />
                <UserPortalSocial />
            </div>
        </div >
    );
}

export default UserDashboardHome
