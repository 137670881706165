import React, { useState, useEffect, useRef } from 'react';
import { BsLinkedin } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { TfiNewWindow } from "react-icons/tfi";

function Certifications({ certificates }) {
    const [shareColor, setShareColor] = useState(-1)
    const [downloadColor, setDownloadColor] = useState(-1)
    const [certLists, setCertLists] = useState()
    const targetRef = useRef();

    console.log(certificates)

    function getDate(date) {
        var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        let date_return = ''
        const date_tmp = new Date(date)
        date_return += month[date_tmp.getMonth()]
        date_return += (' ' + date_tmp.getFullYear())
        return date_return
    }

    function changeHoverStateEnter(button, i, ind) {
        if (button == 'download') {
            document.getElementById('dowload_cert_' + ind + '_' + i).style.backgroundColor = '#006197'
            document.getElementById('dowload_cert_p_' + ind + '_' + i).style.color = 'white'
            setDownloadColor(i)
        } else if (button == 'share') {
            document.getElementById('share_cert_' + ind + '_' + i).style.backgroundColor = 'white'
            document.getElementById('share_cert_p_' + ind + '_' + i).style.color = '#006197'
            setShareColor(i)
        }
    }

    function changeHoverStateLeave(button, i, ind) {
        if (button == 'download') {
            document.getElementById('dowload_cert_' + ind + '_' + i).style.backgroundColor = 'white'
            document.getElementById('dowload_cert_p_' + ind + '_' + i).style.color = '#006197'
            setDownloadColor(-1)
        } else if (button == 'share') {
            document.getElementById('share_cert_' + ind + '_' + i).style.backgroundColor = '#006197'
            document.getElementById('share_cert_p_' + ind + '_' + i).style.color = 'white'
            setShareColor(-1)
        }
    }

    function certArray(windowSize) {
        const main_cert = []
        var secondary_cert = []
        var num_per_slide = 3

        // Set number of certs in a single carousel div
        if (windowSize > 1600) {
            num_per_slide = 3
        } else if (windowSize > 640) {
            num_per_slide = 2
        } else {
            num_per_slide = 1
        }

        // Set cert array of array with the num_per_slide
        certificates.map((cert, i) => {
            // certs to be included in a single slide
            if (i == 0 || (i % num_per_slide) != 0) {
                secondary_cert.push(cert)
                if ((i + 1) == certificates.length) {
                    main_cert.push(secondary_cert)
                }
            } else {
                // add all certs in one slide as a single array and start new slide
                main_cert.push(secondary_cert)
                secondary_cert = []
                secondary_cert.push(cert)
                if ((i + 1) == certificates.length) {
                    main_cert.push(secondary_cert)
                }
            }
        })

        // Set certLists
        setCertLists(main_cert)
    }

    useEffect(() => {
        // Set Cert double array
        certArray(window.innerWidth)
    }, [])

    useEffect(() => {
        const handleResize = () => {
            // Set Cert double array
            certArray(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array means this effect only runs once after the component mounts


    return (
        <div className='user_dashboard_home_profile_div'>
            <div>
                <p className='user_dashboard_home_journey_title'>Your Certifications</p>
                <p style={{ textAlign: 'center', fontStyle: 'italic', fontSize: '18px', marginTop: '40px' }}>Certifications may take up to 3-5 days after you pass an exam to display on the portal</p>
            </div>

            {certLists ?
                <Carousel showStatus={false}>
                    {certLists.map((certificates_array, ind) => (
                        <div className='user_dashboard_home_certifications_all_cert' key={ind}>
                            {certificates_array.map((cert, i) => (
                                <div className='user_dashboard_home_certifications_indiv_cert' key={i}>
                                    <a href={cert.url} target='_blank' rel="noreferrer">
                                        <div className='user_dashboard_home_certifications_indiv_cert_img_div'>
                                            <img src={cert.certificate.image.preview} className='user_dashboard_home_certifications_indiv_cert_img' />
                                            <img src={cert.badge.image.preview} className='user_dashboard_home_certifications_indiv_cert_img_badge' />
                                        </div>
                                    </a>
                                    <div className='user_dashboard_home_certifications_indiv_cert_download_share'>
                                        <a href={cert.url} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
                                            <div className='user_dashboard_home_certifications_indiv_cert_download' id={'dowload_cert_' + ind + '_' + i}
                                                onMouseEnter={() => changeHoverStateEnter('download', i, ind)}
                                                onMouseLeave={() => changeHoverStateLeave('download', i, ind)}>
                                                <TfiNewWindow size={17} color={downloadColor == i ? 'white' : '#006197'} />
                                                <p id={'dowload_cert_p_' + ind + '_' + i}>View</p>
                                            </div>
                                        </a>
                                        <a href={"https://www.linkedin.com/sharing/share-offsite/?url=" + (cert.badge.image.preview)}
                                            target="_newwin"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none' }}>
                                            <div className='user_dashboard_home_certifications_indiv_cert_share' id={'share_cert_' + ind + '_' + i}
                                                onMouseEnter={() => changeHoverStateEnter('share', i, ind)}
                                                onMouseLeave={() => changeHoverStateLeave('share', i, ind)}>
                                                <BsLinkedin color={shareColor == i ? '#006197' : 'white'} />
                                                <p id={'share_cert_p_' + ind + '_' + i}>Share</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='user_dashboard_home_certifications_indiv_cert_date_div'>
                                        <p>Issue Date: {getDate(cert.issued_on)}</p>
                                        <p>Expiry Date: {getDate(cert.expired_on)}</p>
                                        <div ref={targetRef} style={{ width: '800px', height: '800px', display: 'none' }} id='test'>
                                            <img src='/dca 3.png' style={{ width: '800px', height: '800px' }} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </Carousel>
                : null
            }
        </div >
    );
}

export default Certifications
