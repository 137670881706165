import React, { useState, useEffect } from "react";

import { getFirestore, doc, query, where, orderBy, collection, getDocs, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import MemberNavbar from "../../components/Pages/MemberArea/Navbar/Navbar.js";
import UserPortalAllCourses from "../../components/Pages/UserPortalAllCourses/UserPortalAllCourses.js";
import { Helmet } from "react-helmet"

export default function UserAllCourses() {

    const [user, setUser] = useState()
    const [certificates, setCertificates] = useState()
    const [userInfo, setUserInfo] = useState()
    const [courses, setCourses] = useState()
    const [previousCourses, setPreviousCourses] = useState()
    const [userType, setUserType] = useState("user")
    const [pendingCourses, setPendingCourses] = useState()

    const [companyMembershipInfo, setCompanyMembershipInfo] = useState()

    const [errorAccured, setErrorAccured] = useState()

    useEffect(() => {
        const db = getFirestore();
        getAuth().onAuthStateChanged(async user => {
            if (user) {
                // set user
                setUser(user)

                // get certifications
                var request = new XMLHttpRequest();
                request.open('POST', 'https://api.accredible.com/v1/credentials/search');
                request.setRequestHeader('Content-Type', 'application/json');
                request.setRequestHeader('Authorization', 'Token token=54dc431a507f5f49a059b8b5d1f96427');
                request.onreadystatechange = function () {
                    if (this.readyState === 4) {
                        var credentials_temp = JSON.parse(this.responseText).credentials
                        var cert_list = []
                        credentials_temp.map((cred) => {
                            const cred_name = cred.group_name.replace('®', '')
                            if (cred_name == 'DCIS' || cred_name == 'DCES'
                                || cred_name == 'DCOS' || cred_name == 'DCIE'
                                || cred_name == 'DCOM' || cred_name == 'DCTP'
                                || cred_name == 'DCM' || cred_name == 'DCE'
                                || cred_name == 'DCA')
                                cert_list.push(cred)
                        })

                        setCertificates(cert_list)
                    } else setCertificates([])
                };
                var body = {
                    'recipient': {
                        'email': user?.email
                    }
                };
                request.send(JSON.stringify(body));

                // get user info
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserInfo(docSnap.data())
                    var all_courses = []
                    var all_event_id = []
                    docSnap.data().courses?.map(i => all_event_id.push(i.eventID))


                    const now = new Date()
                    const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

                    if (all_event_id.length > 0) {
                        const eventRef = collection(db, "Event");
                        var q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(0, 10)), orderBy("startDateTime"));
                        var querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            if (doc.data().type == 'Training')
                                all_courses.push(doc.data())
                        });
                        if (all_event_id.length > 10) {
                            q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(10, 18)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                if (doc.data().type == 'Training')
                                    all_courses.push(doc.data())
                            });
                        }
                        if (all_event_id.length > 18) {
                            q = query(eventRef, where('startDateTime', '>=', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(18, 30)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                if (doc.data().type == 'Training')
                                    all_courses.push(doc.data())
                            });
                        }
                    }
                    setCourses(all_courses)

                    const prev_courses = []
                    if (all_event_id.length > 0) {
                        const eventRef = collection(db, "Event");
                        var q = query(eventRef, where('startDateTime', '<', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(0, 10)), orderBy("startDateTime"));
                        var querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            if (doc.data().type == 'Training')
                                prev_courses.push(doc.data())
                        });
                        if (all_event_id.length > 10) {
                            q = query(eventRef, where('startDateTime', '<', lastWeek.toISOString()), where('_id', 'in', all_event_id.slice(10, 18)), orderBy("startDateTime"));
                            querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                if (doc.data().type == 'Training')
                                    prev_courses.push(doc.data())
                            });
                        }
                    }
                    setPreviousCourses(prev_courses)


                    // Pending Pot Courses
                    const pending_tmp = []
                    const pendingRef = collection(db, "Pending Pot");
                    var q = query(pendingRef, where('uid', '==', user.uid));
                    var querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        pending_tmp.push(doc.data())
                    });
                    setPendingCourses(pending_tmp)
                } else {
                    // doc.data() will be undefined in this case
                    setErrorAccured('Member Document Not Found')
                }
            } else {
                window.location.href = "/login";
            }
        })
    }, [])


    return (
        <div>
            <Helmet>
                <title>Courses</title>
            </Helmet>
            <link rel="icon" href="/favicon.png" />
            <link rel="apple-touch-icon" href="/idca-logo-with-bg.jpg" />

            <nav>
                {userInfo &&
                    <MemberNavbar user={userInfo} companyMembershipInfo={companyMembershipInfo} page='home' />
                }
            </nav>

            <main className="Saira_Medium_Black">
                <UserPortalAllCourses userInfo={userInfo} courses={courses} certificates={certificates} previousCourses={previousCourses} pendingCourses={pendingCourses} />
            </main>
        </div >
    )
}